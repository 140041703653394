import React, { useEffect, useState } from 'react';
import './App.scss';
import './Styles/Master.scss';
import firebase from 'firebase';
import firebaseConfig from '../src/chatapp/views/chat/config';
import Routes from './Routes/index';
import { actionCreators } from "./Redux"
import { useSelector, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { ChakraProvider } from '@chakra-ui/react';
import { Toaster } from 'react-hot-toast';
// import runOneSignal  from './Utils/webpush';
import "firebase/messaging";
import toast from 'react-hot-toast';

var messaging;
if (!firebase.apps.length) {
    firebase.initializeApp(firebaseConfig);
} 
messaging = firebase.messaging();



function App() {
  const state = useSelector(state=>state)
  const dispatch = useDispatch();
  const { products2,customersList,admins,country,phoneCountryInput} = bindActionCreators(actionCreators,dispatch)

  useEffect(()=>{
    customersList('q=')
    // admins()
    country()
    phoneCountryInput()
    products2()
    // runOneSignal()
    messaging.onMessage((payload) => {
      console.log("Message received:", payload);
    
      if (payload.data && payload.data.chat_url) {
          toast.success("New message received! Click to open.", {
              duration: 5000,
              style: { cursor: "pointer" }, 
              onClick: () => {
                  window.open(payload.data.chat_url, "_blank"); // Open chat link
              }
          });
      }else{
        toast.success("New message received! Click to open.", {
          duration: 5000,
          style: { cursor: "pointer" }, 
          onClick: () => {
              window.open('https://google.com', "_blank"); // Open chat link
          }
      });
      }
    });

  },[])

  useEffect(()=>{
    console.log(">>>REDUX",state)
  },[state])
  
  return (

    <ChakraProvider>
      <Toaster/>
      <div>
      {<Routes name={messaging}  />}
      </div>
    </ChakraProvider>
  );
}

export default App;
