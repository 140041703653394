import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app';
import { Provider } from 'react-redux';
import { Store } from './Redux';

if ('serviceWorker' in navigator) {
  navigator.serviceWorker.getRegistrations().then(regs => {
      if (regs.length === 0) {  
          navigator.serviceWorker.register('/firebase-messaging-sw.js')
          .then((registration) => {
              console.log('Service Worker Registered:', registration);
          })
          .catch((error) => {
              console.log('Service Worker Registration Failed:', error);
          });
      } else {
        if(regs.length > 1){
          console.log("Found moere then do worker so deleting one:");
          navigator.serviceWorker.getRegistrations().then((registrations) => {
            registrations.forEach((registration) => {
              registration.unregister();
            });
          });
        }else{
          console.log("Service Worker Already Registered:", regs);
        }
         
      }
  });
}


ReactDOM.render(
  <React.StrictMode>
    <Provider store={Store}>
      <App />
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
