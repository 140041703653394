export default {
    apiKey: "AIzaSyBvrZAKKsDJKDdA6PRbR2ajB9pIrKBL8Jc",
    authDomain: "mmh-dashboard.firebaseapp.com",
    databaseURL: "https://mmh-dashboard.firebaseio.com",
    projectId: "mmh-dashboard",
    storageBucket: "mmh-dashboard.appspot.com",
    messagingSenderId: "961729071732",
    appId: "1:961729071732:web:1f580df37cfd7e43ad1f21",
    measurementId: "G-QDJ7PEN0BK"
};
